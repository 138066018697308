



























import { APagination } from "@/hooks";
import { DailyOperationalReportMapper } from "@/mapper/DailyOperationalReport.mapper";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  DailyOperationalReportState,
  DailyOperationalWithdrawReportDto,
} from "@/models/interface/daily-operational-report";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { dailyOperationalReportService } from "@/services/daily-operational-report.service";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class TabUnitWithdrawal extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Prop({ required: false, default: undefined })
  readonly filter!: DailyOperationalReportState;

  @Prop({ required: false, default: false })
  readonly isActive!: boolean;

  dtReport: Pagination<DailyOperationalWithdrawReportDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };
  pagination = {
    limit: DEFAULT_PAGE_SIZE,
    page: DEFAULT_PAGE,
  };
  loading = false;

  columns = [
    {
      title: this.$t("lbl_bapu"),
      dataIndex: "bapuNumber",
    },
    {
      title: this.$t("lbl_delivery_order_number"),
      dataIndex: "doNumber",
    },
    {
      title: this.$t("lbl_ic_number"),
      dataIndex: "icNumber",
    },
    {
      title: this.$t("lbl_rent_period"),
      dataIndex: "rentPeriod",
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
    },
    {
      title: this.$t("lbl_customer_code"),
      dataIndex: "customerCode",
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerLocation",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
    },
    {
      title: this.$t("lbl_note"),
      dataIndex: "note",
      customRender: (text: string) => text || "-",
    },
  ];

  @Watch("filter", { immediate: true })
  onChangeForm(newValue: DailyOperationalReportState): void {
    if (!this.isActive) return;
    const params = this.toParams(newValue);
    this.pagination.page = 1;
    params.page = 0;
    this.fetchReport(params);
  }

  async fetchReport(params?: RequestQueryParamsModel): Promise<void> {
    try {
      this.loading = true;
      this.$emit("loading", this.loading);
      const response =
        await dailyOperationalReportService.getListReportDailyOperationalWithdraw(
          params
        );
      this.dtReport = response;
    } finally {
      this.loading = false;
      this.$emit("loading", this.loading);
    }
  }

  toParams(state: DailyOperationalReportState): RequestQueryParamsModel {
    return DailyOperationalReportMapper.toWithdrawalParamReport(state);
  }

  onChangeTable({ current, pageSize }: APagination): void {
    this.pagination.page = current;
    if (pageSize !== this.pagination.limit) {
      this.pagination.page = DEFAULT_PAGE;
    }
    this.pagination.limit = pageSize;

    const params = this.toParams(this.filter);
    params.page = this.pagination.page - 1;
    params.limit = this.pagination.limit;
    this.fetchReport(params);
  }
}
