







































































import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import {
  TabUnitDelivery,
  TabUnitExchange,
  TabUnitWithdrawal,
} from "@/components/DailyOperationalReport";
import { useBlob, useDate } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { DailyOperationalReportState } from "@/models/interface/daily-operational-report";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { dailyOperationalReportService } from "@/services/daily-operational-report.service";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";

type TabKey = "unit-delivery" | "unit-exchange" | "unit-withdrawal";

@Component({
  components: {
    SelectBranch,
    SelectCustomer,
  },
})
export default class IndexPage extends Mixins(MNotification) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  @Ref("form")
  form!: FormModel;

  loading = false;
  loadingDownload = false;
  formState: DailyOperationalReportState = {
    branch: undefined,
    date: null,
    customer: undefined,
  };
  currentTab: TabKey = "unit-delivery";
  tabs = [
    {
      title: this.$t("lbl_unit_delivery"),
      key: "unit-delivery",
      component: TabUnitDelivery,
    },
    {
      title: this.$t("lbl_unit_exchange"),
      key: "unit-exchange",
      component: TabUnitExchange,
    },
    {
      title: this.$t("lbl_unit_withdrawal"),
      key: "unit-withdrawal",
      component: TabUnitWithdrawal,
    },
  ];

  filter: DailyOperationalReportState = { ...this.formState };

  handleSubmit(): void {
    this.filter = { ...this.formState };
  }

  handleReset(): void {
    this.form.resetFields();
    this.formState.date = null;
  }

  toParamsDownload(
    state: DailyOperationalReportState
  ): RequestQueryParamsModel {
    const { toDefaultFormat, toEndDay, toStartDay } = useDate();
    const company =
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA";
    const branch = state.branch ? state.branch.label : "ALL";
    const date = state.date ? toDefaultFormat(state.date) : "ALL";
    const headers = [company, branch, date];
    return {
      params: headers.join(","),
      branch: state.branch ? state.branch.key : null,
      dateFrom: state.date ? toStartDay(state.date).format() : null,
      dateTo: state.date ? toEndDay(state.date).format() : null,
      customer: state.customer ? state.customer.key : null,
    };
  }

  async handleDownload(): Promise<void> {
    const { toDownload } = useBlob();
    try {
      const params = this.toParamsDownload(this.formState);
      this.loadingDownload = true;
      const response =
        await dailyOperationalReportService.downloadReportDailyOperational(
          params
        );
      toDownload(response, "daily_operational_report.xlsx");
    } catch {
      this.showNotifError("notif_download_error");
    } finally {
      this.loadingDownload = false;
    }
  }

  onChangeTab(): void {
    const { filter } = this;
    this.filter = { ...filter };
  }
}
