var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_daily_operational_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: { model: _vm.formState, "label-align": "left" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: "8" } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "branch", label: _vm.$t("lbl_branch") } },
                    [
                      _c("SelectBranch", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.formState.branch,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "branch", $$v)
                          },
                          expression: "formState.branch"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "8" } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "date", label: _vm.$t("lbl_date") } },
                    [
                      _c("a-date-picker", {
                        staticClass: "w-100",
                        attrs: {
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_date")
                          }),
                          format: _vm.DEFAULT_DATE_FORMAT
                        },
                        model: {
                          value: _vm.formState.date,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "date", $$v)
                          },
                          expression: "formState.date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "8" } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        prop: "customer",
                        label: _vm.$t("lbl_customer_name")
                      }
                    },
                    [
                      _c("SelectCustomer", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.formState.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "customer", $$v)
                          },
                          expression: "formState.customer"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { "html-type": "button" },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading,
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: "24" } },
            [
              _c(
                "a-tabs",
                {
                  attrs: { type: "card" },
                  on: { change: _vm.onChangeTab },
                  model: {
                    value: _vm.currentTab,
                    callback: function($$v) {
                      _vm.currentTab = $$v
                    },
                    expression: "currentTab"
                  }
                },
                _vm._l(_vm.tabs, function(tab) {
                  return _c(
                    "a-tab-pane",
                    { key: tab.key, attrs: { tab: tab.title } },
                    [
                      _c(tab.component, {
                        tag: "component",
                        attrs: {
                          "is-active": _vm.currentTab === tab.key,
                          filter: _vm.filter
                        },
                        on: {
                          loading: function(ev) {
                            return (_vm.loading = ev)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16], justify: "end" } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingDownload },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }