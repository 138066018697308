import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { HttpClient } from "@/services/HttpClient.service";
import {
  DailyOperationalDeliveryReportDto,
  DailyOperationalExchangeReportDto,
  DailyOperationalWithdrawReportDto,
} from "@interface/daily-operational-report";
import { AxiosError } from "axios";

export class DailyOperationalReportService extends HttpClient {
  getListReportDailyOperationalDelivery(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<DailyOperationalDeliveryReportDto>> {
    return this.get<Pagination<DailyOperationalDeliveryReportDto>>(
      Api.ReportDailyOperational + "/delivery",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListReportDailyOperationalExchange(
    params?: RequestQueryParamsModel
  ): Promise<DailyOperationalExchangeReportDto[]> {
    return this.get<DailyOperationalExchangeReportDto[]>(
      Api.ReportDailyOperational + "/exchange",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListReportDailyOperationalWithdraw(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<DailyOperationalWithdrawReportDto>> {
    return this.get<Pagination<DailyOperationalWithdrawReportDto>>(
      Api.ReportDailyOperational + "/withdrawl",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReportDailyOperational(
    params?: RequestQueryParamsModel
  ): Promise<Blob> {
    return this.get<Blob>(Api.ReportDailyOperational + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const dailyOperationalReportService =
  new DailyOperationalReportService();
