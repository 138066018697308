import { SearchBuilder } from "@/builder";
import { useDate } from "@/hooks";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DailyOperationalReportState } from "@/models/interface/daily-operational-report";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";

export class DailyOperationalReportMapper {
  static toParams(state: DailyOperationalReportState): RequestQueryParamsModel {
    const { toStartDay, toEndDay } = useDate();
    const params = new RequestQueryParams();
    const queries: string[] = [];
    if (state.branch) {
      const queryBranch = new SearchBuilder()
        .push(["location.warehouse.branchWarehouse.secureId", state.branch.key])
        .build();
      queries.push(queryBranch);
    }

    if (state.customer) {
      const queryCustomer = new SearchBuilder()
        .push(["deliveryOrder.customer.secureId", state.customer.key])
        .build();
      queries.push(queryCustomer);
    }

    if (state.date) {
      const queryDate = new SearchBuilder()
        .push(["deliveryOrder.date", toStartDay(state.date).format()], {
          het: true,
        })
        .and()
        .push(["deliveryOrder.date", toEndDay(state.date).format()], {
          let: true,
        })
        .build();
      queries.push(queryDate);
    }

    params.search = queries.join(SearchBuilder.AND);

    return params;
  }

  static toWithdrawalParamReport(
    state: DailyOperationalReportState
  ): RequestQueryParamsModel {
    const { toStartDay, toEndDay } = useDate();
    const defaultQuery = new SearchBuilder()
      .push(["withdrawlNumber", "null"], { not: true })
      .build();
    const queries: string[] = [defaultQuery];
    const params = new RequestQueryParams();

    if (state.branch) {
      const queryBranch = new SearchBuilder()
        .push(["internalContract.branch.secureId", state.branch.key])
        .build();
      queries.unshift(queryBranch);
    }

    if (state.customer) {
      const queryCustomer = new SearchBuilder()
        .push(["internalContract.customer.secureId", state.customer.key])
        .build();
      queries.unshift(queryCustomer);
    }

    if (state.date) {
      const queryDate = new SearchBuilder()
        .push(["withdrawalDate", toStartDay(state.date).format()], {
          het: true,
        })
        .and()
        .push(["withdrawalDate", toEndDay(state.date).format()], {
          let: true,
        })
        .build();
      queries.unshift(queryDate);
    }

    params.search = queries.join(SearchBuilder.AND);
    return params;
  }

  static toUnitExchangeParamReport(
    state: DailyOperationalReportState
  ): RequestQueryParamsModel {
    const { toStartDay, toEndDay } = useDate();
    const params = new RequestQueryParams();
    const defaultQuery = new SearchBuilder()
      .push(["bastNumberNew", "null"], { not: true })
      .build();
    const queries: string[] = [defaultQuery];

    if (state.branch) {
      const queryBranch = new SearchBuilder()
        .push(["branchId", state.branch.key])
        .build();
      queries.unshift(queryBranch);
    }

    if (state.date) {
      const queryDate = new SearchBuilder()
        .push(["replacementDate", toStartDay(state.date).format()], {
          het: true,
        })
        .and()
        .push(["replacementDate", toEndDay(state.date).format()], {
          let: true,
        })
        .build();
      queries.unshift(queryDate);
    }

    if (state.customer) {
      const queryCustomer = new SearchBuilder()
        .push(["customerId", state.customer.key])
        .build();
      queries.unshift(queryCustomer);
    }

    params.search = queries.join(SearchBuilder.AND);

    return params;
  }
}
