























import { generateUUID } from "@/helpers/uuid";
import { DailyOperationalReportMapper } from "@/mapper/DailyOperationalReport.mapper";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import {
  DailyOperationalExchangeReportDto,
  DailyOperationalReportState,
  DailyOperationalUnitExchangeState,
} from "@/models/interface/daily-operational-report";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { dailyOperationalReportService } from "@/services/daily-operational-report.service";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class TabUniExchange extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Prop({ required: false, default: undefined })
  readonly filter!: DailyOperationalReportState;

  @Prop({ required: false, default: false })
  readonly isActive!: boolean;

  loading = false;
  dataSource: DailyOperationalExchangeReportDto[] = [];
  columns = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      width: "75px",
      customRender: (text: number, _, index: number) => {
        const obj = {
          children: text,
          attrs: {} as { rowSpan: number; colSpan: number },
        };
        if (index % 2 === 0) {
          obj.attrs.rowSpan = 2;
        } else if (index % 2 === 1) {
          obj.attrs.rowSpan = 0;
        }
        return obj;
      },
      fixed: "left",
      align: "center",
    },
    {
      title: this.$t("lbl_bast"),
      dataIndex: "bastNo",
    },
    {
      title: this.$t("lbl_delivery_order_number"),
      dataIndex: "documentNumber",
    },
    {
      title: this.$t("lbl_ic_number"),
      dataIndex: "icNumber",
    },
    {
      title: this.$t("lbl_rent_period"),
      dataIndex: "rentPeriod",
    },
    {
      title: this.$t("lbl_branch_name"),
      dataIndex: "branchName",
      width: "150px",
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
    },
    {
      title: this.$t("lbl_customer_code"),
      dataIndex: "customerCode",
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerLocation",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
    },
    {
      title: this.$t("lbl_note"),
      dataIndex: "note",
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      fixed: "right",
      align: "center",
    },
  ];

  get dtReport(): DailyOperationalUnitExchangeState[] {
    const entries: DailyOperationalUnitExchangeState[] = [];
    this.dataSource.forEach((item, i) => {
      entries.push(this.buildOldRow(item, i));
      entries.push(this.buildNewRow(item, i));
    });
    return entries;
  }

  @Watch("filter")
  onChangeForm(newValue: DailyOperationalReportState): void {
    if (!this.isActive) return;
    const params = this.toParams(newValue);
    params.page = 0;
    this.fetchReport(params);
  }

  toParams(state: DailyOperationalReportState): RequestQueryParamsModel {
    return DailyOperationalReportMapper.toUnitExchangeParamReport(state);
  }

  async fetchReport(params?: RequestQueryParamsModel): Promise<void> {
    try {
      this.loading = true;
      this.$emit("loading", this.loading);
      const response =
        await dailyOperationalReportService.getListReportDailyOperationalExchange(
          params
        );
      this.dataSource = response;
    } finally {
      this.loading = false;
      this.$emit("loading", this.loading);
    }
  }

  buildOldRow(
    data: DailyOperationalExchangeReportDto,
    index: number
  ): DailyOperationalUnitExchangeState {
    return {
      no: (index + 1).toString(),
      status: "OLD",
      key: generateUUID(),
      bastNo: data.bastNumberOld,
      documentNumber: data.doNumberOld,
      branchName: data.branchName,
      customerName: data.customerName,
      customerCode: data.customerCode,
      customerLocation: data.customerLocation,
      unitCode: data.unitCodeOld,
      equipment: data.equipmentOld,
      merk: data.merkOld,
      type: data.typeOld,
      serialNumber: data.serialNumberOld,
      note: data.notesOld,
      icNumber: data.icNumber,
      rentPeriod: data.rentPeriod,
    };
  }

  buildNewRow(
    data: DailyOperationalExchangeReportDto,
    index: number
  ): DailyOperationalUnitExchangeState {
    return {
      no: (index + 1).toString(),
      status: "NEW",
      key: generateUUID(),
      bastNo: data.bastNumberNew,
      documentNumber: data.doNumberNew,
      branchName: data.branchName,
      customerName: data.customerName,
      customerCode: data.customerCode,
      customerLocation: data.customerLocation,
      unitCode: data.unitCodeNew,
      equipment: data.equipmentNew,
      merk: data.merkNew,
      type: data.typeNew,
      serialNumber: data.serialNumberNew,
      note: data.notesNew,
      icNumber: data.icNumber,
      rentPeriod: data.rentPeriod,
    };
  }
}
