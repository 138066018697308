var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      size: "small",
      "data-source": _vm.dtReport.data,
      columns: _vm.columns,
      scroll: { x: 2900 },
      loading: _vm.loading,
      "row-key": "id",
      pagination: {
        showTotal: function() {
          return _vm.$t("lbl_total_items", {
            total: _vm.dtReport.totalElements
          })
        },
        total: _vm.dtReport.totalElements,
        showSizeChanger: true,
        pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
        current: _vm.pagination.page,
        defaultPageSize: _vm.pagination.limit
      },
      "row-class-name": function(_record, index) {
        return index % 2 ? "bg-white" : "bg-gray-light"
      }
    },
    on: { change: _vm.onChangeTable }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }